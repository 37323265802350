// lib/gtag.js
// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
  window.gtag('config', 'UA-28518727-1', {
    page_path: url,
  })
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({
  action, category, label, value,
}) => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value,
  })
}

export const experimentImpresion = ({ experimentId, variant }) => {
  window.gtag('event', 'experiment_impression', {
    experiment_id: experimentId,
    variant_id: `${experimentId}.${variant}`,
    send_to: 'G-4XVFM0BLWC',
  })
}

export const purchaseDataLayerEvent = ({
  id, currency, value, title: item_name,
}) => {
  const dataLayer = window.dataLayer || []
  dataLayer.push({ ecommerce: null })
  dataLayer.push({
    event: 'purchase',
    ecommerce: {
      transaction_id: id,
      value,
      currency,
      items: [
        {
          item_name,
        },
      ],
    },
  })
}

export const purchaseEvent = ({
  id, currency, value, title: item_name,
}) => {
  console.log('purchase event success')
  window.gtag('event', 'purchase', {
    transaction_id: id,
    value,
    currency,
    items: [
      { item_name },
    ],
  })
}
