export function sendToDataLayer(data) {
  const dataLayer = window.dataLayer || []
  dataLayer.push(data)
}

// Send information to GTM about an action and a user
function pushToDataLayer({ event, eventCategory, eventAction, aditionalParams }) {
  const dataLayer = window.dataLayer || []
  dataLayer.push({
    event,
    eventCategory,
    eventAction,
    ...aditionalParams,
  })
}

export default pushToDataLayer
