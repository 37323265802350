import React, { Fragment } from 'react'
import { createGlobalStyle } from 'styled-components'
import NProgressCSS from 'utils/nprogress'
import ligthenDarkenColor from 'utils/ligthen-darken-color'

const DefaultStyles = createGlobalStyle`
  :root {
    /* TYPOGRAFY */
    --regular: normal 16px/24px var(--primaryFont);
    --regularBold: bold 16px/24px var(--primaryFont);

    --p2Regular: normal 14px/24px var(--primaryFont);
    --p2Bold: bold 14px/24px var(--primaryFont);

    --smallRegular: normal 12px/20px var(--primaryFont);
    --smallBold: bold 12px/20px var(--primaryFont);

    --headline1: bold 64px/80px var(--primaryFont);
    --headline2: bold 56px/64px var(--primaryFont);
    --headline3: bold 48px/56px var(--primaryFont);
    --headline4: bold 32px/40px var(--primaryFont);
    --headline5: bold 24px/32px var(--primaryFont);
    --headline6: bold 20px/32px var(--primaryFont);

    --buttonRegular: 600 16px/24px var(--primaryFont);

    --headerBlockSize: 72px;

    /* Common Colors */
    --primary: ${({ theme }) => theme.primary};
    --primary90: ${({ theme }) => theme.primary90};
    --primary80: ${({ theme }) => theme.primary80};
    --primary70: ${({ theme }) => theme.primary70};
    --primary60: ${({ theme }) => theme.primary60};
    --primaryHover: ${({ theme }) => theme.primaryHover};
    --primaryActive: ${({ theme }) => theme.primaryActive};
    --primaryDisabled: ${({ theme }) => theme.primaryDisabled};
    --primaryLight: ${({ theme }) => ligthenDarkenColor(theme.primary, 15)};
    --primaryDark: ${({ theme }) => ligthenDarkenColor(theme.primary, -20)};

    /* Secondary Colors */
    --secondary: ${({ theme }) => theme.secondary};
    --secondary70: #6876FF;
    --secondary80: ${({ theme }) => theme.secondary80};
    --secondary140: #0A004E;
    --secondaryDisabled: ${({ theme }) => theme.secondaryDisabled};
    --secondaryHover: ${({ theme }) => theme.secondaryHover};
    --secondaryActive: ${({ theme }) => theme.secondaryActive};
    --secondaryGradient: ${({ theme }) => theme.secondaryGradient};

    /* Talently */
    --talentlyBlue: #3A00E0;
    --talentlyRed: #FF4343;
    --talentlyWhite: #f9f9fb;
    --talentlyPurple: #2A1E4F;

    --lightBlue: ${({ theme }) => theme.lightBlue};
    --darkBlue: ${({ theme }) => theme.darkBlue};

    /* Gray Scale */
    --black: ${({ theme }) => theme.black};
    --black50: #8B8D8D;
    --white: ${({ theme }) => theme.white};

    --neutral10: ${({ theme }) => theme.neutral10};
    --neutral20: ${({ theme }) => theme.neutral20};
    --neutral30: ${({ theme }) => theme.neutral30};
    --neutral40: ${({ theme }) => theme.neutral40};
    --neutral50: ${({ theme }) => theme.neutral50};
    --neutral60: ${({ theme }) => theme.neutral60};
    --neutral70: ${({ theme }) => theme.neutral70};
    --neutral80: ${({ theme }) => theme.neutral80};
    --neutral90: ${({ theme }) => theme.neutral90};
    --neutral100: ${({ theme }) => theme.neutral100};
    --neutral110: ${({ theme }) => theme.neutral110};

    --primaryFont: ${({ theme }) => theme.primaryFont};
    --secondaryFont: ${({ theme }) => theme.secondaryFont};
    --projects-color: #08002f;

     /* DARK AND LIGHT COLORS */
     /* Base colors */
    --basePrimary: ${({ theme }) => theme.white};
    --baseSecondary: ${({ theme }) => theme.neutral100};
    --baseTertiary: ${({ theme: { light } }) => light.baseTertiary};
    --baseNeutral: ${({ theme }) => theme.neutral100};
    /* Sky colors */
    --sky: ${({ theme }) => theme.neutral10};
    --sky-inverted: ${({ theme }) => theme.neutral110};
    --skyGradient: linear-gradient(180deg, #DFFBFF 35.7%, #DFFBFF 57.28%);
    --turquoise: ${({ theme: { light } }) => light.turquoise};
    --invertedTurquoise: ${({ theme: { dark } }) => dark.turquoise};
    /* Errors */
    --error: ${({ theme: { light } }) => light.error};
    --success: ${({ theme: { light } }) => light.success};
    /* Text */
    --lightText: #212429;
    --textField: ${({ theme: { light } }) => light.textField};
    --textOpaque: ${({ theme }) => theme.neutral90};
    --neutralText: ${({ theme }) => theme.neutral100};
    --paragraphColor: ${({ theme }) => theme.neutral90};


    --gray: ${({ theme: { light } }) => light.gray};
    --neutral: ${({ theme: { light } }) => light.neutral};
    --white80: #4B4D50;
    --lightGray: ${({ theme: { light } }) => light.lightGray};
    --background: ${({ theme: { light } }) => light.background};
    --baseFullContrast: ${({ theme }) => theme.neutral100};
  /* } */
  /* @media (prefers-color-scheme: dark) { */
    /* :root { */
      /* Base colors */
      --basePrimary: ${({ theme }) => theme.neutral100};
      --baseSecondary: ${({ theme }) => theme.white};
      --baseTertiary: ${({ theme: { dark } }) => dark.baseTertiary};
      --baseNeutral: ${({ theme }) => theme.neutral10};
      /* Sky colors */
      --sky: ${({ theme }) => theme.neutral110};
      --sky-inverted: ${({ theme }) => theme.neutral10};
      --skyGradient: linear-gradient(180deg, #04001E 45.46%, #073555 50.62%, #06002D 57.28%);
      --turquoise: ${({ theme: { dark } }) => dark.turquoise};
      --invertedTurquoise: ${({ theme: { light } }) => light.turquoise};
      /* Errors */
      --error: ${({ theme: { dark } }) => dark.error};
      --success: ${({ theme: { dark } }) => dark.success};
      /* Text */
      --lightText: #ffffff99;
      --textField: ${({ theme: { dark } }) => dark.textField};
      --textOpaque: ${({ theme }) => theme.neutral60};
      --neutralText: ${({ theme }) => theme.white};
      --paragraphColor: ${({ theme }) => theme.white};


      --gray: ${({ theme: { dark } }) => dark.gray};
      --neutral: ${({ theme: { dark } }) => dark.neutral};
      --white80: #ffffffcc;
      --lightGray: ${({ theme: { dark } }) => dark.lightGray};
      --background: ${({ theme: { dark } }) => dark.background};
      --baseFullContrast: ${({ theme }) => theme.white};
    /* } */
  }
  * {
    margin:0;
  }
  html {
    scroll-behavior: smooth;
  }
  ul {
    list-style: none;
    padding: 0;
  }
  ${({ theme: { fontFace } }) => (fontFace && fontFace)};
  body {
    font-family: ${({ theme: { fontFamily } }) => (fontFamily || 'var(--primaryFont)')};
    background: ${({ theme: { background } }) => (background || 'var(--sky)')};
    color: var(--baseSecondary);
    font-size: 16px;
    // scrollbar with no layout shift
    scrollbar-gutter: stable;
    overflow: overlay;
  }
  #__next {
    @media(min-width: 1024px) {
      height: ${({ theme: { height } }) => (height)};
      overflow: ${({ theme: { overflow } }) => (overflow)};
    }
    // performance power ??
    /* content-visibility: auto; */
    /* contain-intrinsic-size: 800px; */
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  img {
    vertical-align: middle;
  }
  @keyframes important {
    from {
      box-shadow: 0 0 9px 0px #ffb3d6;
    }
    to {
      box-shadow: 0 0 12px 3px #ff8abb;
    }
  }
  @keyframes pulse {
    from {
      transform: translate(1.5px, 1.5px);
      stroke-opacity: 1;
    }
    10% {
      stroke-opacity: 0.9;
    }
    20% {
      stroke-opacity: 0.8;
    }
    25% {
      transform: translate(-1.5px, 1.5px);
    }
    30% {
      stroke-opacity: 0.7;
    }
    40% {
      stroke-opacity: 0.6;
    }
    50% {
      stroke-opacity: 0.5;
      transform: translate(-1.5px, -1.5px);
    }
    60% {
      stroke-opacity: 0.6;
    }
    70% {
      stroke-opacity: 0.7;
    }
    75% {
      transform: translate(1.5px, -1.5px);
    }
    80% {
      stroke-opacity: 0.8;
    }
    90% {
      stroke-opacity: 0.9;
    }
    to {
      stroke-opacity: 1;
      transform: translate(1.5px, 1.5px);
    }
  }
`

const BaseStyles = ({ theme }) => (
  <Fragment>
    <DefaultStyles {...theme} />
    <NProgressCSS />
  </Fragment>
)

export default BaseStyles
