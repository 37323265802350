
export default {
  light: {
    gray: '#AEB7C1',
    lightGray: '#f9f8ff',
    baseTertiary: '#f9f8ff',
    background: '#ffffff',
    dark: '#1F1F3A',
    secondaryContrast: '#706c61',
    light: '#ffffff',
    error: '#AC0016',
    success: '#11d40e',
    textField: '#444',
    lightText: '#212429',
    turquoise: '#3f69ff',
    neutral: '#3B3F56',
  },
  dark: {
    textField: '#A2A2A2',
    light: '#1F1F3A',
    gray: '#AEB7C1',
    lightGray: '#313434',
    baseTertiary: '#313434',
    background: '#1B1E1E',
    dark: 'white',
    secondaryContrast: '#706c61',
    error: '#F06D7E',
    success: '#11d40e',
    lightText: '#ffffff99',
    turquoise: '#10FEFE',
    neutral: '#8D9DAB',
  },
  secondary80: '#6876FF',
  lightBlue: '#10FEFE',

  neutral: '#AEB7C1',
  neutral10: '#DFFBFF',
  neutral20: '#C4DCE3',
  neutral30: '#A8BCC7',
  neutral40: '#8D9DAB',
  neutral50: '#E8E7E4',
  neutral60: '#D5D4D0',
  neutral70: '#727E8F',
  neutral80: '#565E72',
  neutral90: '#3B3F56',
  neutral100: '#1F1F3A',
  neutral110: '#04001E',

  primary: '#0803D2',
  primary90: '#0704FE',
  primary80: '#373DFE',
  primary70: '#6876FF',
  primary60: '#A9BDFF',

  primaryHover: '#0902A6',
  primaryActive: '#09017A',
  primaryDisabled: '#A9BDFF',
  secondary: '#EF0070',
  secondaryHover: '#FA057C',
  secondaryActive: '#AA0351',
  secondaryDisabled: '#E6BEDE',
  secondaryGradient: 'linear-gradient(218.99deg, #3F69FF -19.82%, #EF0070 100.25%)',
  black: '#212429',
  white: 'white',
  darkBlue: '#06002D',
  primaryFont: 'Montserrat, system-ui, sans-serif',
  secondaryFont: 'Rubik, system-ui, sans-serif',
}
