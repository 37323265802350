function isCourse(path) {
  if (path.includes('cursos') || path.includes('aprender')) return true
  return false
}

function isProyect(path) {
  if (path.includes('proyectos')) return true
  return false
}

function setOrigin() {
  if (window?.location) {
    const { pathname } = window.location
    if (isCourse(pathname)) {
      sessionStorage.setItem('origin', 'course')
    }
    if (isProyect(pathname)) {
      sessionStorage.setItem('origin', 'projects')
    }
  }
}

export default setOrigin
