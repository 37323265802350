import React from 'react'
import NextHead from 'next/head'
import PropTypes from 'prop-types'

function Head({
  title, description, keyword, url, image,
}) {
  const img = image.startsWith('/') ? `https://leonidasesteban.com${image}` : image

  return (
    <NextHead>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} key="title" />
      <meta property="og:description" content={description} key="description" />
      <meta property="og:url" content={url} key="url" />
      <meta property="og:image" content={img} key="image" />
      <meta property="og:type" content="website" />
      <meta property="fb:app_id" content="396291198665148" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image:src" content={img} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="keywords" content={keyword} />
    </NextHead>
  )
}

Head.defaultProps = {
  title: 'Leonidas Esteban, Google Developer Expert en Web Technologies',
  description: 'Te enseño el cómo y el porqué de cada línea de código, aprendamos a programar juntos.',
  image: 'https://leonidasesteban.com/images/open-graph/aprendizaje-basado-en-proyectos.jpg',
  url: 'https://leonidasesteban.com',
  keyword: 'Leonidas Esteban, Cursos de Javascript, Cursos de React, Cursos de Figma, Cursos de HTML, Cursos de CSS, Aprende a programar',
}

Head.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.string,
  keyword: PropTypes.string,
}

export default Head
