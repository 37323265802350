import { StrictMode, useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import Head from 'next/head'
import NProgress from 'nprogress'
import Router from 'next/router'
import Cookies from 'universal-cookie'
import { CookiesProvider } from 'react-cookie'
import { SessionProvider } from 'next-auth/react'
import { prepareClientPortals } from '@jesstelford/react-portal-universal'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Hydrate } from 'react-query/hydration'
import theme from 'utils/theme'
import BaseStyles from 'components/base-styles'
import OpenGraph from 'common/open-graph'
import setOrigin from 'utils/set-origin'
import * as gtag from 'utils/gtag'
import { sendToDataLayer } from 'utils/push-to-datalayer'
import { fbqEvent } from 'utils/fbq'
import datadogConfig from 'utils/datadog.config'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      // refetchOnMount: false,
      // staleTime: Infinity,
    },
  },
})

const { fetch } = global
// eslint-disable-next-line func-names
global.fetch = function (endpoint, config, res, req) {
  return fetch(endpoint, config)
    .then((response) => {
      if (endpoint.includes('auth/login/')) return Promise.resolve(response)
      if (response.status === 401) {
        if (res && req) {
          const { url } = req
          res.setHeader('Set-Cookie', 'user_token=deleted; path=/; max-age=0; expires=Thu, 01 Jan 1970 00:00:00 GMT')
          res.setHeader('Set-Cookie', 'user_payload=deleted; path=/; max-age=0; expires=Thu, 01 Jan 1970 00:00:00 GMT')
          res.clearCookie('user_token', { path: '/', expires: new Date(0) })
          res.clearCookie('user_payload', { path: '/', expires: new Date(0) })
          res.writeHead(302, {
            location: `/login?next=${url}`,
          })
          res.end()
          return Promise.reject(new Error('User Unauthorized'))
        }
        if (typeof window !== 'undefined') {
          const cookies = new Cookies()
          cookies.remove('user_token', { path: '/' })
          cookies.remove('user_payload', { path: '/' })
          const next = window.location.pathname
          Router.push(`/login?next=${next}`)
          return Promise.resolve(response)
        }
      }
      return Promise.resolve(response)
    })
}


if (typeof window !== 'undefined') {
  // On the client, we have to run this once before React attempts a render.
  // Here in _app is a great place to do it as this file is only required once,
  // and right now (outside the constructor) is before React is invoked.
  prepareClientPortals()
}

Router.onRouteChangeStart = () => {
  NProgress.start()
  setOrigin()
}

Router.onRouteChangeComplete = () => {
  NProgress.done()
}
Router.onRouteChangeError = () => {
  NProgress.done()
}

function LeonidasEsteban({
  Component, pageProps, err, router: { asPath },
}) {
  const { pageTheme } = Component
  useEffect(() => {
    datadogConfig()
    const acepted = ['utm_source=sendinblue', 'utm_source=email', 'utm_medium=email']
    const visitFromEmail = acepted.some((param) => asPath.includes(param))
    if (visitFromEmail) {
      fbqEvent({ event: 'visit_from_email' })
      sendToDataLayer({ event: 'visit_from_email' })
    }

    const handleRouteChange = (url) => {
      gtag.pageview(url)
    }
    Router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])
  return (
    <ThemeProvider theme={{ ...theme, ...pageTheme }}>
      <>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover" />
        </Head>
      </>
      <OpenGraph />
      <BaseStyles theme={{ ...theme, ...pageTheme }} />
      <SessionProvider>
        <CookiesProvider>
          <QueryClientProvider client={queryClient}>
            <Hydrate state={pageProps.dehydratedState}>
              <StrictMode>
                <Component {...pageProps} err={err} />
                <div id="page-portal" />
              </StrictMode>
              <ReactQueryDevtools initialIsOpen={false} />
            </Hydrate>
          </QueryClientProvider>
        </CookiesProvider>
      </SessionProvider>
    </ThemeProvider>
  )
}

export default LeonidasEsteban
