import { datadogRum } from '@datadog/browser-rum'

export default function datadogConfig() {
  datadogRum.init({
    applicationId: process.env.DATADOG_APP_ID_FRONT,
    clientToken: process.env.DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'leonidasesteban.com',
    env: process.env.NODE_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    enableExperimentalFeatures: ['clickmap'],
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  })
  datadogRum.startSessionReplayRecording()
}
